<template>
  <div>
    <confirm-modal ref="confirmModal" />
    <template>
      <b-card>
        <div class="d-flex justify-content-center flex-column">
          <b-row class="topbar mb-1">
            <b-col class="d-flex justify-content-between">
              <div></div>
              <div>
                <b-button
                  variant="secondary"
                  @click="openInviteUserModel"
                  size="sm"
                >
                  {{ T("Web.Generic.Users.Invite") }}
                  <feather-icon icon="UserPlusIcon" />
                </b-button>
              </div>
            </b-col>
            <loader class="loader" ref="loader"></loader>
          </b-row>
        </div>
        <b-table
          responsive
          :fields="fields"
          :items="accountUsers"
          primary-key="code"
          show-empty
          :empty-text="T('Web.Generic.TableEmpty', 'No items to show')"
        >
          <template #cell(alias)="data">
            <input
              type="text"
              class="form-control"
              v-model="data.value"
              disabled
            />
          </template>
          <template #cell(email)="data">
            <input
              type="text"
              class="form-control"
              v-model="data.value"
              disabled
            />
          </template>
          <template #cell(role)="data">
            <b-form-select
              v-model="data.item.role"
              @input="updateUser(data.item)"
            >
              <b-form-select-option value="SupSupplier"
                >Adgang til tildelte bestillinger</b-form-select-option
              >
              <b-form-select-option value="Supplier"
                >Adgang til alle bestillinger</b-form-select-option
              >
            </b-form-select>
          </template>
          <template #cell(actions)="data">
            <b-button
              @click="removeAccountUser(data.item)"
              variant="danger"
              class="mr-2 float-right"
              size="sm"
            >
              {{ T("Web.Generic.Remove") }}
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-button
              v-if="!data.item.hasPassword"
              variant="secondary"
              @click="reinviteUser(data.item.email)"
              size="sm"
              class="mr-2 float-right"
            >
              {{ T("Web.Generic.Users.ReInvite") }}
              <feather-icon icon="RefreshCcwIcon" />
            </b-button>
          </template>
        </b-table>
      </b-card>
    </template>
    <invite-user-model
      :showName="true"
      :hideRole="true"
      ref="inviteUserModel"
    ></invite-user-model>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import loader from "@/components/layout/loader.vue";
import InviteUserModel from "@/components/modals/auth/InviteUserModel.vue";
import ConfirmModal from "@/components/modals/ConfirmModal.vue";

import {
  BCard,
  BButton,
  BTable,
  BRow,
  BCol,
  BCardTitle,
  BFormSelect,
  BFormSelectOption,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BButton,
    BTable,
    BRow,
    BCol,
    BCardTitle,
    BFormSelect,
    BFormSelectOption,
    loader,
    InviteUserModel,
    ConfirmModal,
  },
  async created() {
    await this.fetchAccountUsers();
  },
  data() {
    return {
      service: null,
      active: "collectors",
      activeLoading: false,
      type: "border",
      fields: [
        {
          key: "alias",
          label: this.T("Web.Generic.Name"),
          sortable: true,
        },
        {
          key: "email",
          label: this.T("Web.Generic.Email"),
          sortable: true,
        },
        {
          key: "role",
          label: this.T("Web.Generic.Access", "Access"),
          sortable: true,
        },
        {
          key: "actions",
          label: "",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      accountUsers: "accountUsers/list",
    }),
  },
  methods: {
    ...mapActions({
      fetchAccountUsers: "accountUsers/fetchList",
      inviteUser: "accountUsers/reinviteUser",
      putAccountUsers: "accountUsers/put",
      updateAccountUsers: "accountUsers/update",
      deleteAccountUsers: "accountUsers/delete",
    }),
    async removeAccountUser(accountuser) {
      if (
        !(await this.$refs.confirmModal.confirm(
          this.T("Web.Generic.Users.ConfirmRemove")
        ))
      )
        return;

      this.$refs.loader.loading();
      await this.deleteAccountUsers({ resourceId: accountuser.id });
      await this.fetchAccountUsers();

      this.$refs.loader.loadCompelete();
    },
    async reinviteUser(email) {
      this.$refs.loader.loading();
      this.inviteUser({ email: email });
      this.$refs.loader.loadCompelete();
    },
    async openInviteUserModel() {
      let result = await this.$refs.inviteUserModel.open();
      if (result) await this.fetchAccountUsers();
    },
    async updateAccountUser(accountuser) {
      this.$refs.loader.loading();
      await this.putAccountUsers({ data: accountuser });
      this.$refs.loader.loadCompelete();
    },
    async updateUser(user) {
      this.$refs.loader.loading();
      await this.updateAccountUser(user);
      this.$refs.loader.loadCompelete();
    },
  },
};
</script>

<style lang="scss" scoped>
.topbar {
  position: relative;
  .loader {
    position: absolute;
    top: -15px;
    right: 0;
  }
}
.row {
  align-items: end;
}
.rowitem {
  &.offset-top {
    &:first-of-type {
      margin-top: 0;
    }
    margin-top: 10px;
  }
}
.offset-top {
  margin-top: 20px;
}
</style>